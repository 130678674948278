@import 'styles/main';
@import 'cookieBot';
@import './slick-slider';
@import './text';
@import './responsive-text';
@import './autocomplete';
@import './checkbox';
@import './Button';
@import './Card';
@import './Link';
@import './Tab';
@import './Img';
@import './Tag';
@import './ContentLayout';
@import './Input';
@import './LeafletMap';
@import './Accordion';
@import './Modal';
@import './ProductChart';
@import './Navigation';
@import './Header';
@import './Error';
@import './Innovation';
@import './ClippedImage';
@import './dynamic-bg-color.scss';
@font-face {
  font-family: 'Noto Serif';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: local('Noto Serif'), url('/fonts/Noto_Serif/NotoSerif-Regular.woff2') format('woff2'),
    url('/fonts/Noto_Serif/NotoSerif-Regular.woff') format('woff'),
    url('/fonts/Noto_Serif/NotoSerif-Regular.ttf') format('ttf');
  unicode-range: U+000-5FF; /* Latin glyphs */
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: local('Montserrat'), url('/fonts/Montserrat/Montserrat-Regular.woff2') format('woff2'),
    url('/fonts/Montserrat/Montserrat-Regular.woff') format('woff'),
    url('/fonts/Montserrat/Montserrat-Regular.ttf') format('ttf');
  unicode-range: U+000-5FF; /* Latin glyphs */
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src: local('Montserrat'), url('/fonts/Montserrat/Montserrat-Medium.woff2') format('woff2'),
    url('/fonts/Montserrat/Montserrat-Medium.woff') format('woff'),
    url('/fonts/Montserrat/Montserrat-Medium.ttf') format('ttf');
  unicode-range: U+000-5FF; /* Latin glyphs */
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: block;
  src: local('Montserrat'), url('/fonts/Montserrat/Montserrat-SemiBold.woff2') format('woff2'),
    url('/fonts/Montserrat/Montserrat-SemiBold.woff') format('woff'),
    url('/fonts/Montserrat/Montserrat-SemiBold.ttf') format('ttf');
  unicode-range: U+000-5FF; /* Latin glyphs */
}

* {
  touch-action: pan-y, pan-x;
}

body {
  margin: 0;
  color: $color-primary-1;
  -webkit-overflow-scrolling: touch;
}

main {
  display: block; // fix IE11
}

.paused {
  animation-play-state: paused !important;
}

.running {
  animation-play-state: running !important;
}

.meteo-button-1 {
  background-color: $green;
  padding: $spacing-s;
  border-radius: $spacing-basis;
  color: $white;
}

.meteo-button-2 {
  background-color: $white;
  border-radius: $spacing-basis;
  border: 2px solid $green;
  color: $green;
  padding: $spacing-s;
}

.header-button {
  min-width: max-content !important;
  border-radius: $spacing-basis;
}

.tag {
  @extend %tag;
}
.big {
  font-size: 22px;
  line-height: 34px;
  font-family: $font-family-1;
  font-weight: 400;
}

.new-big {
  font-size: 22px;
  line-height: 34px;
  font-family: $font-family-1;
  font-weight: 400;
}

.bold {
  font-size: 18px;
  line-height: 34px;
  font-family: $font-family-1;
  font-weight: bold;
}

.new-bold {
  font-size: 17px;
  line-height: 34px;
  font-family: $font-family-1;
  font-weight: bold;
}

.quote {
  @extend %quote;
}
.tiny-title {
  font-size: 12px;
  line-height: 14px;
  font-family: $font-family-1;
  font-weight: 400;
}
.date {
  font-size: 12px;
  line-height: 14px;
  font-family: $font-family-1;
  font-weight: 500;
}
.p-style-h2 {
  font-size: 38px;
  line-height: 42px;
  font-family: $font-family-1;
  font-weight: 600;
}
.p-style-h3 {
  font-size: 28px;
  line-height: 32px;
  font-family: $font-family-1;
  font-weight: 600;
}
.p-h3-upper-style {
  font-size: 20px;
  line-height: 30px;
  font-family: $font-family-1;
  font-weight: 600;
  text-transform: uppercase;
}
.p-h4-upper-style {
  font-size: 30px;
  line-height: 30px;
  font-family: $font-family-1;
  font-weight: 600;
  text-transform: uppercase;
}
.new-p-style-h3 {
  font-size: 22px;
  line-height: 30px;
  font-family: $font-family-1;
  font-weight: 600;
}
.new-h3 {
  font-size: 22px;
  line-height: 26px;
  font-family: $font-family-1;
  font-weight: 600;
}
.menu-link {
  font-size: 12px;
  font-family: $font-family-1;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

// 🏎 Animations
@keyframes scale-in {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes appear-up {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
}

@keyframes list-item-in {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

//Slider adaptive height animation
.slick-list {
  transition: height $time-very-short ease-out;
}

p a {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

h2 a {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

em.plc-pro {
  font-style: normal;
  color: $color-secondary-1;
}

// Text colors (flavours)
@each $flavour, $color in $text-flavours {
  .#{'' + $flavour} {
    color: $color;
  }
  .background-#{'' + $flavour} {
    background-color: $color;
  }
}

.overlay-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include media-breakpoint-up(m) {
    padding: $spacing-l;
    $max-width-overlay: 70%;
    position: absolute;
    width: $max-width-overlay;
    left: calc((100% - $max-width-overlay) / 2);
  }
}
.not-allowed {
  display: none;
  @include media-breakpoint-up(m) {
    opacity: 0.1;
    cursor: not-allowed;
    display: block;
  }
}
.wrapper-s {
  @extend %wrapper-s;
}
.wrapper-l {
  @extend %wrapper-l;
}

ul.plc-dropdown {
  z-index: 7;
  position: absolute;
  background: $white;
  transform-origin: bottom;

  &.opened {
    transition: transform 200ms ease-in-out, opacity 200ms ease-in;
    transform: none;

    li {
      transition: opacity 200ms ease-in 150ms;
    }
  }
  &.closed {
    pointer-events: none;
    transition: transform 100ms ease-in-out 80ms;
    transform: translateY(-100%) scaleY(0);
    opacity: 0;

    li {
      transition: opacity 100ms ease-out;
      opacity: 0;
    }
  }
}

.grid-m-2-s-1 {
  @include grid-m-2-s-1();
}

.new-grid-m-2-s-1 {
  @include new-grid-m-2-s-1();
}

.grid-m-3-s-1 {
  @include grid-m-3-s-1();
}

.grid-m-4-s-1 {
  @include grid-m-4-s-1();
}

.grid-m-3-s-2 {
  @include grid-m-3-s-2();
}

.grid-l-3-m-2-s-1 {
  @include grid-l-3-m-2-s-1();
}

.no-focus-outline {
  *:focus-within,
  *:focus,
  *:focus + .checkbox-bg,
  *:focus > .basic-button {
    outline: none !important;
  }
}

$paragraph-max-width: 750px;
.page-content {
  overflow-x: hidden;
}
.plc-page {
  margin-bottom: $spacing-l;
  min-height: 70vh;
  .plc-paragraph {
    margin-bottom: $spacing-basis;
    max-width: $paragraph-max-width;
  }
  @include media-breakpoint-up(m) {
    margin-bottom: $spacing-xxl;
  }
}
.plc-text-not-full-width {
  max-width: $paragraph-max-width;
}
.plc-container-not-full-width {
  max-width: $content-container-max-width;
}

.plc-form-element {
  max-width: $form-input-max-width;
  width: 100%;
  &.full-width {
    max-width: unset;
  }
  &.small-input {
    max-width: 198px;
  }
}

.single-line-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@include generateResponsiveClasses(
  display,
  (
    inline-flex: inline-flex,
    inline-block: inline-block,
    flex: flex,
    block: block,
    none: none,
  )
);
@include generateResponsiveClasses(
  float,
  (
    float-left: left,
  )
);
@include generateResponsiveClasses(
  align-items,
  (
    align-center: center,
    align-start: flex-start,
    align-end: flex-end,
    align-baseline: baseline,
  )
);
@include generateResponsiveClasses(
  flex-direction,
  (
    column: column,
    row: row,
    column-reverse: column-reverse,
    row-reverse: row-reverse,
  )
);
@include generateResponsiveClasses(
  justify-content,
  (
    space-between: space-between,
    space-around: space-around,
    space-evenly: space-evenly,
    justify-center: center,
    justify-start: flex-start,
    justify-end: flex-end,
  )
);
@include generateResponsiveClasses(
  flex-wrap,
  (
    wrap: wrap,
    nowrap: nowrap,
  )
);
@include generateResponsiveClasses(
  flex,
  (
    flex-1: 1 1 auto,
    flex-1-0: 1 0 auto,
  )
);
@include generateResponsiveClasses(
  text-align,
  (
    text-center: center,
    text-left: left,
    text-right: right,
  )
);
@include generateResponsiveClasses(
  width,
  (
    width-100: 100%,
    width-50: 50%,
  )
);
@include generateResponsiveClasses(
  max-width,
  (
    // max-width-66: #{100% * 2 / 3},
      max-width-66: calc(100% * 2 / 3)
  )
);
@include generateResponsiveClasses(
  border-bottom,
  (
    border-bottom: 1px solid $color-neutral-2,
  )
);
@include generateResponsiveClasses(
  border-top,
  (
    border-top: 1px solid $color-neutral-2,
  )
);
@include generateResponsiveClasses(
  text-transform,
  (
    capitalize: capitalize,
  )
);
@include generateResponsiveClasses(
  white-space,
  (
    white-space-nowrap: nowrap,
  )
);
@include generateResponsiveClasses(
  cursor,
  (
    cursor-pointer: pointer,
  )
);
@include generateResponsiveClasses(
  pointer-events,
  (
    pointer-events-none: none,
  )
);
@include generateResponsiveClasses(
  word-break,
  (
    word-break-word: break-word,
  )
);

// Need 2 different loops to make sure the classes with breakpoints come last
@each $spacing, $spacing-value in $spacings {
  .plc-m-#{$spacing} {
    margin: $spacing-value;
  }
  .plc-mb-#{$spacing} {
    margin-bottom: $spacing-value;
  }
  .plc-mr-#{$spacing} {
    margin-right: $spacing-value;
  }
  .plc-neg-mr-#{$spacing} {
    margin-right: -$spacing-value;
  }
  .plc-mt-#{$spacing} {
    margin-top: $spacing-value;
  }
  .plc-neg-mt-#{$spacing} {
    margin-top: -$spacing-value;
  }
  .plc-ml-#{$spacing} {
    margin-left: $spacing-value;
  }
  .plc-my-#{$spacing} {
    margin-top: $spacing-value;
    margin-bottom: $spacing-value;
  }
  .plc-mx-#{$spacing} {
    margin-left: $spacing-value;
    margin-right: $spacing-value;
  }
  .plc-neg-mx-#{$spacing} {
    margin-left: -$spacing-value;
    margin-right: -$spacing-value;
  }
  .plc-p-#{$spacing} {
    padding: $spacing-value;
  }
  .plc-pt-#{$spacing} {
    padding-top: $spacing-value;
  }
  .plc-pr-#{$spacing} {
    padding-right: $spacing-value;
  }
  .plc-pb-#{$spacing} {
    padding-bottom: $spacing-value;
  }
  .plc-pl-#{$spacing} {
    padding-left: $spacing-value;
  }
  .plc-py-#{$spacing} {
    padding-top: $spacing-value;
    padding-bottom: $spacing-value;
  }
  .plc-px-#{$spacing} {
    padding-left: $spacing-value;
    padding-right: $spacing-value;
  }
}
@each $breakpoint, $breakpoint-value in $breakpoints {
  @include media-breakpoint-up($breakpoint) {
    @each $spacing, $spacing-value in $spacings {
      .plc-m-up-#{$breakpoint}-#{$spacing} {
        margin: $spacing-value;
      }
      .plc-mb-up-#{$breakpoint}-#{$spacing} {
        margin-bottom: $spacing-value;
      }
      .plc-mr-up-#{$breakpoint}-#{$spacing} {
        margin-right: $spacing-value;
      }
      .plc-mt-up-#{$breakpoint}-#{$spacing} {
        margin-top: $spacing-value;
      }
      .plc-neg-mt-up-#{$breakpoint}-#{$spacing} {
        margin-top: -$spacing-value;
      }
      .plc-ml-up-#{$breakpoint}-#{$spacing} {
        margin-left: $spacing-value;
      }
      .plc-my-up-#{$breakpoint}-#{$spacing} {
        margin-top: $spacing-value;
        margin-bottom: $spacing-value;
      }
      .plc-mx-up-#{$breakpoint}-#{$spacing} {
        margin-left: $spacing-value;
        margin-right: $spacing-value;
      }
      .plc-neg-mx-up-#{$breakpoint}-#{$spacing} {
        margin-left: -$spacing-value;
        margin-right: -$spacing-value;
      }
      .plc-p-up-#{$breakpoint}-#{$spacing} {
        padding: $spacing-value;
      }
      .plc-pt-up-#{$breakpoint}-#{$spacing} {
        padding-top: $spacing-value;
      }
      .plc-pr-up-#{$breakpoint}-#{$spacing} {
        padding-right: $spacing-value;
      }
      .plc-pb-up-#{$breakpoint}-#{$spacing} {
        padding-bottom: $spacing-value;
      }
      .plc-pl-up-#{$breakpoint}-#{$spacing} {
        padding-left: $spacing-value;
      }
      .plc-py-up-#{$breakpoint}-#{$spacing} {
        padding-top: $spacing-value;
        padding-bottom: $spacing-value;
      }
      .plc-px-up-#{$breakpoint}-#{$spacing} {
        padding-left: $spacing-value;
        padding-right: $spacing-value;
      }
    }
  }
}

#CybotCookiebotDialog {
  & {
    bottom: 0 !important;
    top: unset !important;
    padding-bottom: 18px !important;

    div:first-child {
      max-width: 100%;
    }

    #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper {
      display: flex !important;
      justify-content: center;
    }
  }
}

.lazyload-wrapper {
  width: 100%;
  height: 100%;
}

.text-with-arrow {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  .next-icon {
    width: $spacing-m;
    margin-left: $spacing-s;
  }
  @each $flavour, $color in $text-flavours {
    &.#{$flavour} .next-icon path {
      fill: $color;
    }
  }
}

.no-padding {
  padding-left: 0;
  padding-right: 0;
}
.p-style-h4 {
  color: #007461;
  text-transform: uppercase;
  font-size: 28px;
  line-height: 32px;
  font-family: $font-family-1;
  font-weight: 600;
}

.InlineVideoIndex {
  .mini_player{
    position: absolute;
    z-index: 10;
  }
}