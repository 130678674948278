@import '/styles/main';

$inner-cell-width: 32px;
$padding-width: 3px;
$cell-width: #{$inner-cell-width + 2 * $padding-width};

.ui-table-container {
  #ui-table-content {
    th {
      position: sticky;
      left: 0;
      @include media-breakpoint-down(l) {
        left: $spacing-m;
      }
    }
  }

  .ui-table {
    overflow-x: scroll;
    @extend %hide-scrollbar;
    margin: 0 auto;
  }
  .table-left-item {
    min-width: 115px;
  }

  .row-items {
    min-width: $inner-cell-width;
    padding: $spacing-m 8px;
  }

  .header-items {
    width: $cell-width;
    padding: $spacing-m 0;
    min-width: $cell-width;
  }

  // Item
  .flex-table-content-item {
    width: $inner-cell-width;
    @extend .flexCenter;
    @extend .flexTableCellDimension;
    margin: 0 auto;
    .treatment-schedule-td {
      display: flex;
      justify-content: center;
      align-items: center;
      width: $inner-cell-width;
      height: $inner-cell-width;
      @extend .dynamic-bg-color;
    }
    .icon-reason-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      svg {
        width: 100%;
      }
    }
  }

  .flex-table-content-item-header {
    span {
      white-space: nowrap;
    }
  }

  tbody {
    td,
    th {
      border-bottom: none;
      padding: 0 $padding-width;
    }
    @include media-breakpoint-down(l) {
      td {
        padding-top: 21px;
      }
      th {
        transform: translateY(-20px);
      }
    }
  }
  thead {
    td {
      width: $inner-cell-width;
      padding: $spacing-s 2px;
      background-color: $white;
      text-align: center;

      &:not(:first-child) {
        border-bottom: solid 1px $grey-cloud-medium;
      }
    }
  }

  .flex-table-content-item-empty {
    width: $inner-cell-width;
    @extend .flexCenter;
    @extend .flexTableCellDimension;
    .treatment-schedule-td {
      display: flex;
      justify-content: center;
      align-items: center;
      width: $inner-cell-width;
      height: $inner-cell-width;
    }
  }
}
// utils
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexTableCellDimension {
  height: $inner-cell-width;
  margin: $spacing-s auto;
}


.dynamic-bg-color {
  &.bg-darkorange {
    background-color: #ff574b;
  }
  &.bg-middleorange {
    background-color: #ffa569;
  }
  &.bg-lightorange {
    background-color: #ffd314;
  }
  &.bg-lightgreen {
    background-color: #84af4b;
  }
  &.bg-darkgreen {
    background-color: #00a378;
  }
}