.plc-accordion {
    padding: 0 $spacing-m;
    background-color: $grey-cloud-ultralight;
  
    > .MuiPaper-root {
      background-color: $grey-cloud-ultralight;
    }
  
    .MuiAccordionSummary-root {
      padding: 0;
      height: 58px;
  
      &.Mui-expanded {
        border-bottom: 2px solid $grey;
        min-height: unset;
      }
    }
  
    .MuiAccordionDetails-root {
      padding: $spacing-m 0;
    }
  }