@import 'styles/main';
@import 'styles/responsive-text';

div.tab-group {
  overflow: hidden;
  @include media-breakpoint-up(m) {
    .MuiTabs-root {
      @include wrapper(1024px);
    }
  }
  .MuiTabs-flexContainer {
    height: 100%;
  }
  .active {
    display: block;
  }
  .non-active {
    display: none;
  }

  $button-top-down-padding: $spacing-m / 2;
  .single-tab-button {
    padding: $button-top-down-padding 18px;
    border-bottom: 3px solid $color-neutral-3;
    @extend .h5;
    text-transform: none;
    &:hover {
      border-bottom: 3px solid $color-primary-1;
    }

    &:focus {
      outline: none;
    }
    &.Mui-selected {
      &:hover {
        border-bottom: 3px solid transparent;
      }
    }
    h2 {
      font-weight: bold;
    }
  }

  .tab-body {
    @extend %wrapper-s;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    transition: transform 250ms ease-in-out;
    flex: 1;
    position: relative;
    height: calc(100% - 50px);

    & > div {
      width: 100%;
      height: 100%;
      flex-shrink: 0;
      margin-right: 30px;
      overflow: hidden;
      position: relative;
    }

    .inner-tab {
      padding-top: $spacing-basis;
      overflow-x: hidden;
      overflow-y: visible;
      position: relative;
      &.active {
        overflow-x: visible;
      }
    }
  }
}
