@import 'styles/main';

figure.clipped-img {
    display: flex;
    flex-direction: column;
  
    img,
    picture,
    svg,
    .loading {
      flex: 1;
    }
  
    figcaption {
      padding: $spacing-s $spacing-m 0;
      max-width: 325px;
    }
  
    .container {
      overflow: hidden;
    }
  
    @include media-breakpoint-down(m) {
      img,
      picture,
      svg {
        object-fit: cover;
        object-position: bottom;
      }
      figcaption {
        @include z-index(overlay);
        background: rgba($color: $white, $alpha: 0.9);
        padding: 0;
        position: fixed;
        height: 100vh;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        pointer-events: none;
        max-width: unset;
  
        p {
          background-color: $color-primary-1;
          color: white !important;
          padding: $spacing-m;
        }
  
        &.open {
          opacity: 1;
          pointer-events: all;
          transition: opacity $time-short ease-in;
        }
      }
    }
  
    &.settings {
      img,
      picture,
      svg {
        height: 167px;
        width: 100%;
        object-fit: cover;
        object-position: 100% 100%;
        @include media-breakpoint-up(m) {
          height: 300px;
        }
        @media only screen and (min-width: 1440px) {
          height: 300 / 1440 * 100vw;
          max-height: 500px;
        }
      }
    }
    &.content-aside {
      img,
      svg {
        height: 290px;
        width: 100%;
        object-fit: cover;
        @include media-breakpoint-up(m) {
          height: 446px;
          width: 586px;
          min-width: 586px;
        }
      }
    }

    &.default {
      img,
      svg {
        height: 290px;
        width: 100%;
        object-fit: cover;
        @include media-breakpoint-up(m) {
          margin-top: $spacing-basis;
          height: 346px;
          width: 486px;
          min-width: 486px;
        }
      }
    }
  
    @mixin clip-path($path) {
      clip-path: $path!important;
      -webkit-clip-path: $path!important;
    }

    &.innovation-concept-bloc-1 {
      img,
      svg {
        @include clip-path(polygon(5.5% 7%, 77% 0%, 100% 74%, 66% 100%, 0% 91%));
      }
      figcaption {
        padding: 0;
        max-width: 80%;
        p {
          text-align: right;
        }
      }
    }
    &.innovation-concept-bloc-2 {
      img,
      svg {
        @include clip-path(polygon(10% 72%, 50% 100%, 100% 95%, 100% 10%, 20% 0%));
      }
      figcaption {
        padding: 0;
        max-width: 100%;
        p {
          text-align: right;
        }
      }
    }
    &.innovation-concept-bloc-3 {
      img,
      svg {
        @include clip-path(polygon(0% 10%, 65% 0%, 100% 90%, 25% 100%, 0% 95%));
      }
      figcaption {
        padding: 0;
        max-width: 80%;
        p {
          text-align: right;
        }
      }
    }
    &.innovation-contact {
      img,
      svg {
        @include clip-path(polygon(0% 70%, 30% 100%, 100% 95%, 100% 15%, 15% 0%));
      }
      figcaption {
        padding: 0;
        max-width: 100%;
        p {
          text-align: right;
        }
      }
    }
    &.innovation-benefice {
      img,
      svg {
        @include clip-path(polygon(0% 85%, 75% 100%, 100% 95%, 100% 0%, 0% 0%));
      }
      figcaption {
        padding: 0;
        max-width: 100%;
        p {
          text-align: right;
        }
      }
    }
    &.innovation-highlight-card {
      img,
      svg {
        @include clip-path(polygon(10% 65%, 30% 95%, 100% 91%, 100% 13%, 25% 0%));
      }
    }
    &.journal-slide {
      img,
      svg {
        @include clip-path(polygon(5.5% 7%, 77% 0%, 100% 74%, 66% 100%, 0% 91%));
      }
    }
    &.journal-pro {
      img,
      svg {
        @include clip-path(polygon(0% 70%, 20% 0%, 100% 12%, 100% 95%, 33% 100%));
      }
    }
    &.content-aside {
      img,
      svg {
        @include clip-path(polygon(0% 0%, 100% 0%, 100% 90%, 70% 100%, 0% 88%));
        @include media-breakpoint-up(m) {
          @include clip-path(polygon(10% 0%, 90% 0%, 100% 90%, 25% 100%, 0% 65%));
        }
      }
    }
    &.innovationDetailHeader {
      img,
      svg {
        height: 650px;
        width: 100%;
        object-fit: cover;
        @include clip-path(polygon(3% 0%, 100% 0%, 100% 96%, 96% 100%, 50% 100%, 0% 90%, 10% 80%, 0% 35%));
      }
  
      figcaption {
        padding: 0;
        max-width: 100%;
        p {
          text-align: right;
        }
      }
  
      @include media-breakpoint-up(m) {
        img,
        svg,
        figcaption {
          width: 90%;
        }
      }
    }
    &.settings {
      img,
      svg {
        @include clip-path(polygon(0% 0%, 0% 100%, 25% 70%, calc(min(100%, 25% + 658px)) 100%, 100% 100%, 100% 0%));
        @media only screen and (min-width: 1440px) {
          $bottomLeft: 1440 - $spacing-xl;
          $centerTop: 300px;
          $bottomRight: $centerTop + 658px;
          @include clip-path(
            polygon(
              0% 0%,
              0% 100%,
              calc((100% - #{$bottomLeft}) / 2) 100%,
              calc((100% - #{$bottomLeft}) / 2 + #{$centerTop}) calc(100% - 120px),
              calc((100% - #{$bottomLeft}) / 2 + #{$bottomRight}) 100%,
              100% 100%,
              100% 0%
            )
          );
        }
      }
    }
  }
  .actu{
    @include media-breakpoint-down(m) {
      figcaption{
        padding: 2px 24px 0;
        
      }
      p{
        font-size: 10px;
        padding-right: 12px;
      }
    }
  }
  