@import 'styles/main';

.plc-modal {
  .close-button-wrapper {
    align-self: flex-end;

    @include media-breakpoint-up(m) {
      margin-right: -$spacing-m;
    }
  }

  .modal-content {
    flex: 1 1 auto;
    height: 100%;
  }

  h1 {
    margin-bottom: 0;
    max-width: 80%;
  }
  .display-orientation-warning {
    display: none;
  }

  &.force-full-screen {
    @include media-breakpoint-up(m) {
      .close-button-wrapper {
        margin-right: 0;
      }
    }

    .MuiDialog-paper {
      min-width: 100%;
      height: 100vh;
      min-height: 100vh;
      padding: $spacing-m;

      @media screen and (max-width: #{map-get($breakpoints,s) - 1} ) and (orientation: portrait) {
        .modal-content.display-orientation-warning {
          display: flex;
          flex-direction: column;
        }
        .modal-content:not(.display-orientation-warning) {
          display: none;
        }
      }
      @include media-breakpoint-up(s) {
        .modal-content.display-orientation-warning {
          display: none;
        }
        .modal-content:not(.display-orientation-warning) {
          display: flex;
          flex-direction: column;
        }
      }
      @include media-breakpoint-up(l) {
        min-width: 80%;
        min-height: 80vh;
        height: 80vh;
      }
    }
  }
}

:global(.confirmation-modal) {
  .confirmation-modal-buttons {
    text-align: center;

    @include media-breakpoint-up(m) {
      margin-top: $spacing-m;
      > *:not(:last-child) {
        margin-right: $spacing-m;
      }
    }

    @include media-breakpoint-down(m) {
      > * {
        margin: $spacing-m $spacing-m auto auto;
      }
    }
  }
}
