@import 'styles/main';
@import 'styles/content/iFrame';

.content-layout {
  @extend %wrapper-l;
  padding: 0!important;
  display: grid;
  grid-template:
    'illu illu illu' auto
    '. title .' auto
    '. infos .' auto
    '. main .' auto
    '. right .' auto
    '. bottom .' auto
    'readAlsoArticle readAlsoArticle readAlsoArticle' auto
    '. right-mobile-end .' auto / $spacing-m 1fr $spacing-m;
  align-content: start;

  .grid-left {
    grid-area: left;
    justify-self: center;

    .social-share-bar {
      margin-top: 170px;
      display: flex;
      align-items: flex-start;
    }
  }
  .grid-title {
    grid-area: title;
    .pre-title {
      span {
        white-space: normal;
      }
    }
  }

  .grid-main {
    grid-area: main;
  }
  .grid-main,
  .grid-title {
    word-break: break-word;
  }

  .grid-bottom {
    grid-area: bottom;
  }

  .grid-illu {
    grid-area: illu;
    margin-top: 2px;
  }
  .grid-infos {
    grid-area: infos;
  }

  .grid-right {
    grid-area: right;
  }

  .grid-right-mobile-end {
    grid-area: right-mobile-end;
  }

  .grid-readAlsoArticle {
    grid-area: readAlsoArticle;
  }

  .grid-right,
  .grid-bottom,
  .grid-right-mobile-end,
  .grid-readAlsoArticle section > div {
    border-top: 1px solid $grey-cloud-medium;
  }

  &.desktop-layout {
    $minMain: 500px;
    $maxMain: 585px;
    $minRight: 150px;
    $maxRight: 323px;
    $maxIllu: 586px;
    $gutterWithIllu: 0px;
    $gutterWithRight: 116px;
    $secondGutter: $gutterWithRight - $gutterWithIllu;

    grid-template:
      'left title . illu illu illu' minmax(336px, auto)
      'left main . illu illu illu' minmax(1px, auto)
      'left main . . right .' minmax(70vh, 100%)
      'left bottom . . . .' auto
      'readAlsoArticle readAlsoArticle readAlsoArticle readAlsoArticle readAlsoArticle readAlsoArticle' auto / minmax($spacing-m, 1fr) minmax($minMain, $maxMain) $gutterWithIllu minmax(
        0,
        $secondGutter
      )
      minmax($minRight, $maxRight)
      minmax($spacing-m, 1fr);

    grid-template-columns: 1fr $maxMain $gutterWithIllu $secondGutter $maxRight 1fr;
    grid-template-rows: auto auto 1fr auto;

    .grid-illu {
      max-width: $maxIllu;
      height: 100%;
      margin-left: 30px;

      figure {
        height: 100%;
      }
      figcaption {
        padding-left: 0;
        margin-left: $gutterWithIllu;
      }
    }
    .grid-right,
    .grid-right-mobile-end {
      align-self: flex-start;
      justify-content: start;
      border: none;
    }
    .grid-right-mobile-end {
      grid-area: right;
    }
    .grid-infos {
      grid-area: illu;
      margin-right: $spacing-m;
      max-width: $maxRight;
      margin-top: 124px;
      -ms-grid-column: 4;
      -ms-grid-row-span: 3;
    }
  }
}
