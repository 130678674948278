@import 'styles/main';

.navigation {
  .active {
    color: $new-color-primary-1;
  }
  .menu-link {
    min-height: 50px; // to have a descent click area

    &.active {
      font-weight: 600;
    }
  }
}

.navigation .menu-link:hover {
  color: $new-color-primary-1-hover;
}