.plc-modal.product-chart-modal {
    .product-chart-modal-title {
      display: none;
      width: 100%;
  
      @include media-breakpoint-up(m) {
        display: block;
      }
  
      .h3,
      .h4 {
        display: inline-block;
        margin-right: $spacing-s;
      }
    }
  
    .modal-content {
      position: relative;
    }
  }
  
  .product-chart {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    min-height: 100%;
  
    .h4 {
      margin-bottom: $spacing-m;
    }
  
    &-filters {
      display: flex;
      align-self: flex-end;
  
      li {
        flex: 1 1 auto;
        min-width: 65px;
        padding: $spacing-xs $spacing-s;
        background: $grey-cloud-light;
        text-align: center;
        white-space: nowrap;
  
        &.active {
          background-color: $grey-cloud-medium;
        }
  
        button {
          width: 100%;
        }
      }
    }
  
    &-content {
      $filterHeight: 35px;
      flex: 1 1 100%;
      display: flex;
      flex-direction: column;
      height: calc(100% - #{$filterHeight});
      max-height: calc(100% - #{$filterHeight});
    }
  }