.basic-link {
  &.underlined-link {
    @extend %plc-underline;
  }

  @each $flavour, $color in $text-flavours-hover {
    &.#{$flavour}:hover {
      color: $color;
      path {
        fill: $color;
      }
    }
  }
}

.basic-link-wrapper {
  text-decoration: none;
  outline: none;
  border: none;
  padding: 0;
  .basic-link {
    outline: none;
    &:focus {
      outline: none;
    }
  }
  &:focus > .basic-link {
    outline: 1px solid;
    outline-offset: 2px;
  }
}