@include generateResponsiveTexts(
  (
    h1: (
      font-size: 50px,
      line-height: 55px,
      font-family: $font-family-1,
      font-weight: 600,
    ),
    h2: (
      font-size: 38px,
      line-height: 42px,
      font-family: $font-family-1,
      font-weight: 600,
    ),
    h3: (
      font-size: 28px,
      line-height: 32px,
      font-family: $font-family-1,
      font-weight: 600,
    ),
    h4: (
      font-size: 18px,
      line-height: 24px,
      font-family: $font-family-1,
      font-weight: 500,
    ),
    h5: (
      font-size: 16px,
      line-height: 22px,
      font-family: $font-family-1,
      font-weight: 500,
    ),
    h6: (
      font-size: 14px,
      line-height: 18px,
      font-family: $font-family-1,
      font-weight: 500,
    ),
    h7: (
      font-size: 22px,
      line-height: 24px,
      font-family: $font-family-1,
      font-weight: 500,
    ),
    p: (
      font-size: 18px,
      line-height: 34px,
      font-family: $font-family-1,
      font-weight: 400,
    ),
    new-p: (
      font-size: 18px,
      line-height: 34px,
      font-family: $font-family-1,
      font-weight: 400,
    ),
    new-bold-p: (
      font-size: 17px,
      line-height: 34px,
      font-family: $font-family-1,
      font-weight: 500,
    ),
    small: (
      font-size: 16px,
      line-height: 26px,
      font-family: $font-family-1,
      font-weight: 400,
    ),
    new-small: (
      font-size: 16px,
      line-height: 26px,
      font-family: $font-family-1,
      font-weight: 400,
    ),
    tiny: (
      font-size: 12px,
      line-height: 16px,
      font-family: $font-family-1,
      font-weight: 400,
    ),
  )
);
