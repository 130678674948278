.help-icon-container {
  position: absolute;
  top: 0;
  right: 0;
}

label.plc-input {
  display: flex;
  position: relative;
  flex-direction: column;

  header {
    display: flex;
  }

  &:hover {
    .input-box {
      &.is-active {
        border: 1px solid $color-neutral-1;
      }
    }
  }

  &.error .input-box {
    border: 1px solid $color-primary-3;
  }

  .input-box-wrapper {
    @extend %disabled;
  }
  .input-box {
    @include z-index(parent);
    border: 1px solid $color-neutral-2;
    padding: $spacing-s $spacing-m;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $white;

    @include media-breakpoint-down(s) {
      min-width: 0;
    }

    &:focus-within {
      border: 1px solid $color-neutral-1;
      outline: 2px solid $color-neutral-2;
      outline-offset: 2px;
    }
    &.is-disabled {
      background-color: $grey-cloud-light;
    }

    &.left-icon-style {
      border-radius: $spacing-basis;
      box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
    }

    input {
      border: none;
      flex: 1 1 100%;
      color: $color-primary-1;
      max-width: 100%;

      &::placeholder {
        font-weight: 400;
        color: $color-neutral-1;
      }

      &:focus {
        outline: none;
      }
    }
  }
  &.with-right-icon input {
    max-width: calc(
      100% - #{$spacing-m}
    ); //to make sure the input doesn't overflow it's container (drying herb service grass input)
  }
  &.with-left-icon input {
    padding-left: $spacing-s;
    text-align: start;
  }
  .plc-input-hint {
    margin: $spacing-s 0;
  }
}

.plc-inline-input {
  border: none;
  border-bottom: 2px solid $grey-cloud-dark;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  color: $color-primary-1;
  &.error {
    border-color: $color-primary-3;
  }

  &::-ms-expand {
    display: none;
  }
  &:focus-within {
    outline: 2px solid $color-neutral-2;
    outline-offset: 2px;
  }
}
