@import 'styles/main';

.plc-tag-sector > span {
    background-color: $color-primary-2;
    margin-bottom: 1rem;
}
.plc-tag-stake > span {
    background-color: $color-primary-1;
}
.plc-tag-domain > span {
    background-color: $color-primary-3;
}

.innovation-card {
    min-width: 0;
    border: 1px solid $grey-border;
    height: 100%;
  
    header {
      position: relative;
      padding: 0.5rem;
      height: 100%;
  
      .card-image {
        position: relative;
        padding-top: 60%;
        @media (min-width: 350px) and (max-width: 415px) {
          padding-top: 50%;
        }
        @media (min-width: 415px) and (max-width: 768px) {
          padding-top: 30%;
        }
        width: 100%;
        max-height: unset !important;
        @media (min-width: 768px) and (max-width: 965px) {
          margin-bottom: 12px;
          padding-top: 80%;
        }
  
        > * {
          position: absolute;
          top: 0;
          left: 0;
          overflow: hidden;
          height: 150px;
          width: 100%;
          object-fit: cover;
        }
        .lazyload-wrapper {
          display: flex;
        }
        img {
          width: 100%;
          object-fit: contain;
          object-position: center;
        }
  
        &.centered {
          border: 1px solid $grey-border;
  
          > * {
            width: auto;
            height: auto;
            max-width: 80%;
            max-height: 80%;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
          }
        }
      }
  
      .card-header-bottom {
        position: absolute;
        bottom: 2%;
        width: calc(100% - 16px);
        > div {
          padding: 0.3rem 0;
        }
      }
    }
  
    .card-actions {
      display: block;
    }
  
    .card-title,
    .card-description {
      position: relative;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
    .card-title {
      line-height: $spacing-m;
      font-size: $spacing-m;
      font-weight: bolder;
      -webkit-line-clamp: 1;
    }
    .card-highlighted {
      width: 50%;
      position: relative;
      top: -0.5rem;
      left: -0.5rem;
  
      background-color: $yellow-sun-dark;
      clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 20% 100%, 0% 85%);
      p {
        padding: 18px;
        width: 100%;
        font-weight: bold;
        color: $white;
      }
    }
    .min-height {
      min-height: 270px;
    }
  }

.content-tags-list {
  display: inline-flex;
  flex-wrap: wrap;

  &.one-line {
    height: 35px;
    width: 100%;
    overflow: hidden;

    .plc-tag {
      max-width: 100%;

      span {
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &.justify-end .plc-tag {
    margin-right: 0;
    margin-left: $spacing-s;
  }

  .plc-tag {
    margin-bottom: $spacing-s;
  }

}

.content-tags-list-highlighted {
  position: absolute;
  top: $spacing-s;
  right: $spacing-s;
}
  
  
  