.iframe_overlay {
    width: 100%;
    button {
      margin: auto;
      padding: 0 20px;
    }
  }
  .iframe_overlay_layer_cmp {
    position: relative;
    text-align: center;
    color: white;
    display: none;
    vertical-align: middle;
    background-color: rgba(1, 0, 53, 0.7);
    padding: 5% 15%;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    > div {
      padding: 32px;
    }
  }
  .iframe_overlay_title {
    @extend %header-margin !optional;
    font-size: 20px !important;
  }
  
  .iframe_overlay_text {
    @extend .p;
    font-size: 16px !important;
  }
  
  .iframe_overlay_btn_secondaire {
    text-decoration: underline;
    font-size: 16px !important;
  }