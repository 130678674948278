main.error-page {
  min-height: 70vh;
  display: flex;
  flex: 1;

  .container {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    span {
      align-self: stretch;
      text-align: left;
      margin: 0 $spacing-m;
      padding: $spacing-basis 0;
      border-bottom: 1px solid $color-primary-1;
    }
    h1 {
      margin: 0 $spacing-m;
      padding: $spacing-basis 0;
    }
  }

  @include media-breakpoint-up(m) {
    .container {
      @include wrapper-l;
      flex: 1;
      flex-direction: row;

      h1,
      span {
        flex: 1 1 auto;
      }

      span {
        align-self: center;
        padding: 0 $spacing-m;
        border-right: 1px solid $color-primary-1;
        border-bottom: none;
        margin: 0;
        text-align: right;
      }
      h1 {
        padding: 0 $spacing-m;
        margin: 0;
      }
    }
  }
}
