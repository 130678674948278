@import 'styles/main';

// FYI : Mandatory to have this as global due to native ad with these classname.
.content-card {
  min-width: 0;

  header {
    position: relative;

    .card-header-top {
      position: absolute;
      top: $spacing-s;
      right: $spacing-s;
      @include z-index(children);
      max-width: calc(100% - #{$spacing-s} * 2);
    }

    .card-image {
      position: relative;
      padding-top: 83.75%;
      width: 100%;
      max-height: unset !important;

      > * {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      &.centered {
        border: 1px solid $grey-border;

        > * {
          width: auto;
          height: auto;
          max-width: 80%;
          max-height: 80%;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
        }
      }
    }

    .card-header-bottom {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .card-title {
    position: relative;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    @media (min-width: 600px) {
      height: 72px;
    }
  }
}

.pub-content-card {
  background-color: #f4f4f4;
  min-width: 0;
  padding: 10px 0;

  .pub-card-text {
    padding: $spacing-xs 0 $spacing-xs $spacing-xs;
    flex: 1 1 100%;
    display: grid;
    grid-template-rows: 0.5fr 1.5fr;

    .pub-card-top {
      display: flex;
      flex-direction: column-reverse;
    }

    .pub-card-sponsor,
    .pub-card-tag,
    .pub-card-sub-title,
    .pub-card-title {
      color: $midnight-green;
      font-family: 'Montserrat', sans-serif;
    }

    .pub-card-title,
    .pub-card-sub-title {
      align-self: center;
    }

    .pub-card-tag {
      text-transform: uppercase;
    }
    .pub-card-title {
      height: fit-content;
      line-height: normal;
      font-size: 17px;

    }
  }
  .pub-card-image {
    margin-left: auto;
    padding: 0 $spacing-xs;
    > * {
      align-content: center;
    }
  }

  .pub-card-image img {
    width: 210px;
    height: 130px;
    object-fit: cover;
  }

  .pub-card-sub-title {
    display: none;
  }

  @include media-breakpoint-up(m) {
    .pub-card-image img {
      width: 220px;
      height: 160px;
    }
    .pub-card-text {
      grid-template-rows: 0.5fr 2fr 0.5fr;
      .pub-card-top {
        flex-direction: row;
      }

      .pub-card-title {
        font-size: 22px;
      }
    }
    .pub-card-sub-title,
    .pub-card-sponsor {
      display: unset;
    }
  }
}