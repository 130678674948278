@import 'styles/main';

.basic-button-wrapper {
  cursor: pointer;
  @extend %disabled;
  outline: none;
  border: none;
  padding: 0;

  > .basic-button {
    outline: none;
    &:focus {
      outline: none;
    }
  }
  &:focus > .basic-button {
    outline: 2px solid;
    outline-offset: 4px;
  }
}

.basic-button {
  background: transparent;
  transition: filter $time-short ease-in-out;
  padding: 0;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  border: none;
}

.flat-button {
  @mixin button-color-variant($backgroundColor, $background-hover, $borderColor: '', $border-hover: '', $line-width: 1px) {
    background-color: $backgroundColor;
    @if $borderColor != '' {
      border: $line-width solid $borderColor;
      &:hover {
        border-color: $border-hover;
      }
    }
    &:focus {
      @if $borderColor != '' {
        outline-color: $borderColor;
      } @else {
        outline-color: $backgroundColor;
      }
    }
    &:hover {
      background-color: $background-hover;
    }
  }

  $spacing-x-size: 32px;
  padding: $spacing-s;

  @include media-breakpoint-up(l) {
    padding: 14px $spacing-x-size;
  }

  &:not(.underlined) {
    min-width: 125px;
  }
  .next-icon {
    margin-left: $spacing-x-size;
  }
  > span {
    margin: auto;
  }

  &.primary {
    @include button-color-variant($color-primary-3, $color-primary-3-hover);
    &.light-green {
      @include button-color-variant($new-color-primary-2, $new-color-primary-2-hover);
      padding: $spacing-xs $spacing-m;
      border-radius: $spacing-basis;
    }
    &.bleu-turquoise {
      @include button-color-variant($new-color-primary-3, $color-neutral-3);
      border-radius: $spacing-basis;
      &.extra-small:hover,
      &.small:hover {
        .typography {
          color: $new-color-primary-3;
        }
        path {
          fill: $new-color-primary-3;
        }
      }
    }
    &.dark-green {
      @include button-color-variant($new-color-primary-1, $new-color-primary-1-hover);
      padding: $spacing-xs $spacing-m;
      border-radius: $spacing-basis;
    }
    &.pro {
      @include button-color-variant($color-secondary-1, $color-secondary-1-hover);
      &.small:hover {
        background-color: $color-primary-1;
        outline-color: $color-primary-1;
        .typography {
          color: $color-secondary-1;
        }
      }
    }
  }
  &.secondary:not(.dark) {
    @include button-color-variant($white, $white, $grey-border, $color-neutral-1);
    background-color: white;
  }
  &.secondary.meteo-button-2 {
    @include button-color-variant($white, $white, $green, $color-neutral-1, 2px);
    background-color: white;
  }
  &.secondary.dark {
    @include button-color-variant(transparent, rgba(255, 255, 255, 0.2), $white, $white);
    span {
      color: $white;
    }
    svg,
    path {
      fill: $white;
    }
  }
  &.underlined,
  &.small.underlined {
    @extend %plc-underline;
    padding: 0;
  }

  &.small {
    padding: 9px 18px;
  }

  &.extra-small {
    padding: 8px;
  }
}
