@import 'styles/main';

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: $header-height-s;
  position: relative;

  @include media-breakpoint-up(l) {
    padding-top: $header-height-l + $thematic-header-height;
  }

  &-content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }

  :global(#arch_advert) {
    @include media-breakpoint-down(s) {
      > div {
        > div {
          display: none;
        }
      }
    }

    //opacity: 0;
    animation: fade-in 2.5s linear 1s forwards;
    position: absolute !important;
    width: 100%;
    height: calc(100% - #{$header-height-l});
    @include z-index(advert);
    background-color: transparent !important;
    pointer-events: none;
    overflow: hidden;

    > div {
      > div,
      > a {
        pointer-events: all;
      }
    }
  }
}
