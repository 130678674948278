@import 'styles/main';

.plc-tag {
  background-color: $color-primary-2;

  &.dark-green-style {
    background-color: $white;
    border: 2px solid $green;
    button {
      path {
        fill: $green;
      }
    }
  }

  display: inline-flex;
  align-items: center;
  margin-right: $spacing-s;
  padding : {
    left: $spacing-s;
    right: $spacing-s;
  }
  white-space: nowrap;

  &.clickable {
    cursor: pointer;
  }

  &.removable > span {
    padding-right: $spacing-s;
  }

  button {
    background: none;
    cursor: pointer;

    path {
      fill: $white;
    }

    &:focus :global(.basic-button) {
      outline: 2px solid $color-neutral-2;
      outline-offset: 2px;
    }
  }
}
