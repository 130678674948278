@import 'styles/main';

.icon-wrapper {
  background: none;
  border: none;
  margin: 0!important;
  padding: 0;
  cursor: pointer;

  &:focus {
    outline: 2px solid $color-neutral-2;
    outline-offset: 2px;
  }
}
