@import 'styles/main';

label.checkbox,
label.radio {
  $input-size: 20px;

  &.dark-green-style {
    .checkbox-bg {
      border: 2px solid $green;
      border-radius: 3px;

      &.checked {
        background-color: $new-color-primary-1;
      }
    }
    .checkbox-label {
      font-family: "Montserrat", sans-serif;
      font-size: 15px;
    }
  }

  &:not(.multiline-label) {
    height: $input-size;
  }
  font-family: $font-family-1;
  cursor: pointer;
  display: inline-block;
  position: relative;

  .input-wrapper {
    position: relative;
    display: inline-flex;
    align-items: center;
  }

  input:focus + .checkbox-bg {
    outline: 2px solid $color-neutral-2;
    outline-offset: 2px;
  }

  input {
    cursor: pointer;
    margin-right: $spacing-xs;
    width: $input-size;
    min-width: $input-size;
    height: $input-size;
    @include z-index(child);
    opacity: 0;
  }

  .checkbox-bg {
    width: $input-size;
    height: $input-size;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid $color-neutral-2;
    display: block; //fallback IE11 ?
    transition: background-color 100ms ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;

    .check {
      transform: scale(0);
      transition: transform 100ms ease-in;

      path {
        fill: white;
      }
    }

    &.unchecked {
      transition: background-color 100ms ease-in-out 50ms;
    }

    &.checked {
      background-color: $color-primary-2;
      border: none;

      .check {
        transition: transform 100ms ease-in 50ms;
        transform: scale(1);
      }
    }
  }
  &.error .checkbox-bg {
    border: 1px solid $color-primary-3;
  }

  .checkbox-label {
    flex: 1 1 auto;
  }

  &.disabled {
    color: $grey-cloud-dark;
    .checkbox-bg.checked {
      background-color: $grey-cloud-dark;
    }
  }
  .form-hints {
    position: absolute;
    left: #{$input-size + $spacing-xs};
  }
}

label.radio {
  .checkbox-bg {
    border-radius: 50%;

    &:after {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $white;
      transform: scale(0);
      transition: transform 100ms ease-in;
    }

    &.checked:after {
      transition: transform 100ms ease-in 50ms;
      transform: scale(1);
    }
  }
}
