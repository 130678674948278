// Blocks
html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6
  margin: 0
  padding: 0

// Headings
h1,
h2,
h3,
h4,
h5,
h6
  font-size: 100%
  font-weight: normal

// List
ul
  list-style: none

// Form
button,
input,
select,
textarea
  margin: 0
  background: none

button,
input[type='submit'],
input[type='reset']
  background: none
  color: inherit
  border: none
  padding: 0
  font: inherit
  cursor: pointer
  outline: inherit

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

input[type=number]
  -moz-appearance: textfield

// Box sizing
html
  box-sizing: border-box

*
  &,
  &::before,
  &::after
    box-sizing: inherit

// Media
img,
video
  height: auto
  max-width: 100%

// Iframe
iframe
  border: 0

// Table
table
  border-collapse: collapse
  border-spacing: 0

td,
th
  padding: 0
  &:not([align])
    text-align: left

fieldset
  border: none

// Links
a
  text-decoration: none
  color: inherit 