@import 'styles/main';

#CybotCookiebotDialog a,
#CybotCookiebotDialog div,
#CybotCookiebotDialogBodyContentControls {
  font-family: $font-family-1 !important;
}
#CybotCookiebotDialogBodyContentTitle {
  font-family: $font-family-1 !important;
}
#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper {
  display: flex !important;
  flex-wrap: nowrap !important;
  flex-direction: column !important;
  max-width: 100% !important;
  width: 100% !important;
  align-items: center !important;
  > a {
    white-space: pre-wrap !important;
    height: auto !important;
  }
  @include media-breakpoint-up(m) {
    flex-direction: row !important;
  }
}
