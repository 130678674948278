
.react-autosuggest {
    &__container {
      position: relative;
    }
  
    &__input.plc-input {
      margin-bottom: 0;
      max-width: unset;
  
      @include media-breakpoint-up(m) {
        max-width: 282px;
      }
    }
  
    &__suggestions-container {
      position: absolute;
      left: 0;
      // Remove scroll bar, 30px should be enough
      max-width: calc(100vw - 30px);
      min-width: 100%;
      margin-top: -1px;
      padding: $spacing-m;
      border: 1px solid $color-neutral-2;
      background: $white;
      @include z-index(dropdown);
      opacity: 0.5;
      transform: scale(0);
      transform-origin: top left;
      transition: all $time-very-short ease-in-out;
      overflow-y: auto;
      max-height: 300px;
  
      &--open {
        opacity: 1;
        transform: scale(1);
      }
    }
  
    &__section-container:not(:last-child) {
      margin-bottom: $spacing-m;
    }
  
    &__section-title,
    &__suggestion:not(:last-child) {
      margin-bottom: $spacing-s;
    }
  
    &__suggestion {
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      cursor: pointer;
    }
  }
  