.leaflet-map {
    width: 100%;
    height: 100%;
  
    .leaflet-container {
      width: 100%;
      height: 100%;
  
      & * {
        @include z-index(leaflet);
      }
    }
  
    .leaflet-marker-icon.leaflet-div-icon {
      outline: none;
      border: none;
      background: transparent;
    }
  
    .leaflet-bottom.leaflet-right {
      display: none;
    }
  }
  