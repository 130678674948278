@import 'styles/main';

$hamburger-padding-x: 3px;
$hamburger-padding-y: 3px;
$hamburger-layer-width: 18px;
$hamburger-layer-height: 2px;
$hamburger-hover-opacity: 1;
$hamburger-layer-border-radius: 0;
$hamburger-types: (squeeze);

.plc-header  {

  height: $header-height-s;
  @include media-breakpoint-up(l) {
    height: $header-height-l;
  }

  $hamburger-layer-color: $new-color-primary-1;
  @import '~hamburgers/_sass/hamburgers/hamburgers';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  @include z-index(header);
  background: $white;
  border-bottom: 1px solid $color-header-border;

  .search-btn:hover path {
    fill: $new-color-primary-1-hover;
  }

  .plc-header-wrapper {
    height: $header-height-s;
    padding: 0 $spacing-s;
    @include media-breakpoint-up(l) {
      height: $header-height-l;
    }
    button {
      @include media-breakpoint-up(xs){
        margin-left: auto;
      }
    }
  }

  .search-btn,
  .navigation:not(.is-sso),
  .pro-btn {
    display: none;

    > span {
      text-transform: unset;
    }

    &:not(.underlined) {
      min-width: unset;
    }

    @include media-breakpoint-up(l) {
      display: inline-flex;
    }
  }

  .account-btn,
  .connect-btn {
    > span {
      text-transform: unset;
    }
  }

  .plc-logo {
    cursor: pointer;
    width: 158px;
    height: 28px;

    @include media-breakpoint-up(l) {
      width: 225px;
      height: 40px;
    }
  }

  nav {
    display: flex;
    align-items: center;
  }

  .navigation {
    flex-direction: row;
  }

  .search-btn,
  .header-navigation {
    padding: 0 10px;
  }

  .profile-separator {
    display: none;
    width: 1px;
    height: 18px;
    margin: 0 $spacing-xxs;
    background: $grey-border;

    @include media-breakpoint-up(l) {
      display: block;
    }
  }

  .hamburger {
    display: inline-flex;
  }

  @include media-breakpoint-up(l) {
    .navigation .basic-link-wrapper,
    .basic-button-wrapper {
      display: flex;
    }
  }

  .thematic-header {
    display: none;
    @include media-breakpoint-up(l) {
      display: flex;
    }
  }
}

.plc-thematic-header {
  padding: 17px 0;
  border-top: 1px solid $color-header-border;

  @include media-breakpoint-up(l) {
    position: fixed;
    top: $header-height-l;
    height: $thematic-header-height;
    padding: $spacing-xxs 0;
    margin-bottom: $spacing-m;
    border-bottom: 1px solid $color-header-border;
    border-top: none;

    @include z-index(header);

    > div {
      @include wrapper(1392px);
      padding: 0 $spacing-s;
      display: flex;
      justify-content: flex-end;
    }
  }

  width: 100%;
  background: $white;
}